import api from '@apiInstance';

export default {
  add(data) {
    return api.post('clockings', data);
  },
  get(id) {
    return api.fetch('clockings/' + id);
  },
  list(params) {
    return api.fetch('/clockings', { params });
  },
  update(data) {
    return api.patch('clockings', data);
  },
  delete(id) {
    return api.remove('clockings', id);
  },
};
